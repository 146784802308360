<template>
  <div class="value-marker" :style="markerStyle">
    <div class="marker-line"></div>
    <div class="marker-circle"></div>
    <div class="marker-value">
      <!-- {{ value ? value + ' ' + unit : '--' }} -->
      <!-- workaround - for some layers e.g. temperature value 0 was recognized as no value, due to lack of time and low importance we have this workaround -->
      {{ value ? value + ' ' + unit : '0 ' + unit  }}
      <div class="close-button" @click.stop="emitClose">x</div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'ValueMarker',
  emits: ['close-marker'],
  props: {
    position: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    mapHeight: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const markerStyle = computed(() => ({
      left: `${props.position.x}px`,
      top: `${props.position.y}px`
    }));

    const emitClose = () => {
      emit('close-marker');
    };

    return {
      markerStyle,
      emitClose
    };
  }
};
</script>

<style scoped>
.value-marker {
  position: absolute;
  pointer-events: none; /* This prevents interfering with map clicks, 
                           but we will enable clicks on close-button */
}

.marker-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: -3px;
  top: -3px;
  z-index: 2;
}

.marker-line {
  width: 1px;
  height: calc(7vh + 3px);
  background-color: #4a4a4a;
  position: absolute;
  left: 0;
  top: calc(-7vh - 3px);
  z-index: 1;
}

.marker-value {
  position: absolute;
  top: calc(-7vh - 18px);
  left: -1px;
  background-color: #4a4a4a;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  white-space: nowrap;
  
  /* Needed to position the close-button absolutely inside */
  position: relative; 
  pointer-events: auto; /* Allow interaction on this element */
}

.close-button {
  position: absolute;
  top: -5px;
  right: -25px;
  width: 20px;
  height: 20px;
  background-color: #4a4a4a;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
}
</style>
