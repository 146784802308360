<template>
  <div class="time-slider-container">
    <button @click="togglePlayPause" class="play-pause-button">
      <svg v-if="!isPlaying" viewBox="0 0 24 24" class="play-icon">
        <path d="M8 5v14l11-7z" />
      </svg>
      <svg v-else viewBox="0 0 24 24" class="pause-icon">
        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
      </svg>
    </button>
    <div class="slider-and-markers">
      <div class="time-slider" @click="handleClick" ref="sliderRef">
        <div class="time-line"></div>
        <div class="time-marker" :style="{ left: markerPosition + '%' }"></div>
        <TimeTooltip 
          v-if="selectedTimestamp && timeZone" 
          :timestamp="selectedTimestamp" 
          :position="markerPosition" 
          :timeZone="timeZone"
        />
      </div>
      <DayMarkers v-if="timeZone" :timestamps="timestamps" :timeZone="timeZone" />
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import TimeTooltip from './TimeTooltip.vue';
import DayMarkers from './DayMarkers.vue';

export default {
  name: 'TimeSlider',
  components: {
    TimeTooltip,
    DayMarkers
  },
  props: {
    timestamps: {
      type: Array,
      required: true
    }
  },
  emits: ['timestamp-selected'],
  setup(props, { emit }) {
    const sliderRef = ref(null);
    const markerPosition = ref(0);
    const selectedTimestamp = ref(null);
    const timeZone = ref('');
    const isPlaying = ref(false);
    let playInterval = null;

    const parseTimestamp = (timestamp) => {
      const cleanTimestamp = timestamp.replace('/Z3Z', '');
      const matches = cleanTimestamp.match(/^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z$/);
      if (matches) {
        return new Date(Date.UTC(
          parseInt(matches[1]),
          parseInt(matches[2]) - 1,
          parseInt(matches[3]),
          parseInt(matches[4]),
          parseInt(matches[5]),
          parseInt(matches[6])
        ));
      }
      console.error('Failed to parse timestamp:', timestamp);
      return null;
    };

    const findClosestTimestamp = () => {
      const now = new Date();
      const nowUtc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 
                              now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
      
      console.log('Current time (UTC):', new Date(nowUtc).toISOString());

      return props.timestamps.reduce((closest, current) => {
        const currentDate = parseTimestamp(current);
        const closestDate = parseTimestamp(closest);
        
        if (currentDate && closestDate) {
          const currentDiff = Math.abs(currentDate.getTime() - nowUtc);
          const closestDiff = Math.abs(closestDate.getTime() - nowUtc);
          
          console.log('Comparing:', current, 'difference:', currentDiff);
          
          return currentDiff < closestDiff ? current : closest;
        } else {
          console.error('Invalid date parsed for', current, 'or', closest);
          return closest;
        }
      });
    };

    const updateSelectedTimestamp = (timestamp) => {
      selectedTimestamp.value = timestamp;
      const index = props.timestamps.indexOf(timestamp);
      markerPosition.value = (index / (props.timestamps.length - 1)) * 100;
      emit('timestamp-selected', selectedTimestamp.value);
    };

    const moveToNextTimestamp = () => {
      const currentIndex = props.timestamps.indexOf(selectedTimestamp.value);
      const nextIndex = (currentIndex + 1) % props.timestamps.length;
      updateSelectedTimestamp(props.timestamps[nextIndex]);
    };

    const togglePlayPause = () => {
      isPlaying.value = !isPlaying.value;
      if (isPlaying.value) {
        playInterval = setInterval(moveToNextTimestamp, 1000);
      } else {
        clearInterval(playInterval);
      }
    };

    const initializeSlider = () => {
      if (props.timestamps.length > 0) {
        const closestTimestamp = findClosestTimestamp();
        updateSelectedTimestamp(closestTimestamp);
        console.log('Selected timestamp:', closestTimestamp);
      }
    };

    onMounted(() => {
      timeZone.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log('Detected time zone:', timeZone.value);
      console.log('Initial timestamps:', props.timestamps);
      initializeSlider();
    });

    onUnmounted(() => {
      if (playInterval) {
        clearInterval(playInterval);
      }
    });

    const handleClick = (event) => {
      const rect = sliderRef.value.getBoundingClientRect();
      const clickPosition = (event.clientX - rect.left) / rect.width;
      const index = Math.floor(clickPosition * (props.timestamps.length - 1));
      updateSelectedTimestamp(props.timestamps[index]);
    };

    watch(() => props.timestamps, (newValue) => {
      console.log('Timestamps updated:', newValue);
      initializeSlider();
    });

    return {
      sliderRef,
      markerPosition,
      selectedTimestamp,
      handleClick,
      timeZone,
      isPlaying,
      togglePlayPause
    };
  }
};
</script>



<style scoped>
.time-slider-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-bottom: 15px;
}

.play-pause-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4CAF50;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
}

.play-icon, .pause-icon {
  width: 24px;
  height: 24px;
  fill: white;
}

.slider-and-markers {
  flex-grow: 1;
  position: relative;
}

.time-slider {
  height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;
}

.time-line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  background-color: white;
  transform: translateY(-50%);
}

.time-marker {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
</style>