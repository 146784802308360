<template>
  <div class="day-markers">
    <div v-for="(day, index) in dayMarkers" :key="index" class="day-marker" :style="{ left: day.position + '%' }">
      <div class="day-marker-line"></div>
    </div>
    <div v-for="(label, index) in dayLabels" :key="index" class="day-label" :style="{ left: label.position + '%' }">
      {{ label.text }}
    </div>
  </div>
</template>


<script>
import { computed } from 'vue';

export default {
  name: 'DayMarkers',
  props: {
    timestamps: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const parseTimestamp = (timestamp) => {
      // Remove the "/Z3Z" suffix if present
      const cleanTimestamp = timestamp.replace('/Z3Z', '');
      const matches = cleanTimestamp.match(/^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z$/);
      if (matches) {
        return new Date(Date.UTC(
          parseInt(matches[1]),
          parseInt(matches[2]) - 1,
          parseInt(matches[3]),
          parseInt(matches[4]),
          parseInt(matches[5]),
          parseInt(matches[6])
        ));
      }
      return new Date(NaN);
    };

    const dayMarkers = computed(() => {
      const markers = [];
      let currentDay = null;
      props.timestamps.forEach((timestamp, index) => {
        const date = parseTimestamp(timestamp);
        if (date.getDate() !== currentDay) {
          currentDay = date.getDate();
          const position = (index / (props.timestamps.length - 1)) * 100;
          markers.push({ position });
        }
      });
      return markers;
    });

    const dayLabels = computed(() => {
      const labels = [];
      for (let i = 0; i < dayMarkers.value.length - 1; i++) {
        const start = dayMarkers.value[i].position;
        const end = dayMarkers.value[i + 1].position;
        const position = (start + end) / 2;
        const date = parseTimestamp(props.timestamps[Math.floor(position / 100 * props.timestamps.length)]);
        const text = date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' });
        labels.push({ position, text: text.split(' ').reverse().join(' ') });
      }
      return labels;
    });

    return {
      dayMarkers,
      dayLabels
    };
  }
};
</script>


<style scoped>
.day-markers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15px;
}

.day-marker {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
}

.day-marker-line {
  width: 1px;
  height: 15px;
  background-color: black;
}

.day-label {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  white-space: nowrap;
  color: black;
}
</style>